<template>
  <v-app-bar
    app
    flat
    color="secondary"
  >
    <HomeButton
      v-if="pwaMode"
    />
    <PwaModeSwitch
      v-if="homeView && localhost"
    />
    <LanguageSwitcher
      v-if="developmentMode || localhost"
    />
    <v-btn
      icon
      disabled
    >
    </v-btn>
    <v-spacer></v-spacer>
    <div class="text-center title">{{ title }}</div>
    <v-spacer></v-spacer>
    <CloseButton />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'TopBar',
  components: {
    HomeButton: lazyLoad('components/HomeButton'),
    PwaModeSwitch: lazyLoad('components/PwaModeSwitch'),
    CloseButton: lazyLoad('components/CloseButton'),
    LanguageSwitcher: lazyLoad('components/LanguageSwitcher'),
  },
  computed: {
    ...mapGetters('app', [
      'localhost',
      'developmentMode',
      'pwaMode',
      'homeView',
    ]),
    ...mapGetters('testMacro', [
      'all',
    ]),
    ...mapGetters('testMicro', [
      'id',
    ]),
    title() {
      if (this.all.includes(this.id)) {
        return this.$t(`${this.id}.title`);
      }
      if (this.$route.name === 'NotFound') {
        return this.$t('notFound.title');
      }
      return this.$t('home.title');
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 24px !important;
}
</style>
